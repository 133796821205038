import { LightBulbIcon, BookOpenIcon, FaceSmileIcon } from '@heroicons/react/20/solid'

const features = [
  {
    name: 'Unrivaled Solutions',
    description: 'We do more than just eye-catching websites. If you need intelligence solutions to automate your processes and give your customers outstanding interactions, we can help with that.',
    icon: LightBulbIcon,
  },
  {
    name: 'Unparalleled Expertise',
    description: 'Specializing in service industries, we\'ve spent years crafting digital solutions that deliver. We\'re confident that we can integrate our solutions into your existing systems.',
    icon: BookOpenIcon,
  },
  {
    name: 'Unconditional Satisfaction',
    description: 'Your satisfaction is out top priority - we work with you until you\'re 100% happy with what we\'ve built. And most importantly, we won\'t recommend solutions that you don\'t need.',
    icon: FaceSmileIcon,
  },
]

export default function Feature() {
  return (
    <div className="bg-white py-24">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="relative isolate overflow-hidden bg-indigo-950 px-6 py-20 sm:rounded-3xl sm:px-10 sm:py-24 lg:py-24 xl:px-24">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center lg:gap-y-0">
            <div className="lg:row-start-2 lg:max-w-md">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Why work with us?
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                Upgrading customer experience is about more than getting good reviews. It also keeps them coming back and referring new leads, so you can spend less time on acquisition. We help you turn prospects into customers, and keep customers for longer with digital presence transformations that work, driving more traffic to your site, through the door, and multiplying your conversion rates.
              </p>
            </div>
            <img
              src='https://images.unsplash.com/photo-1553877522-43269d4ea984?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80'
              alt="Product screenshot"
              className="relative -z-20 min-w-full max-w-xl rounded-xl shadow-xl ring-1 ring-white/10 lg:row-span-4 lg:w-[64rem] lg:max-w-none -scale-x-100"
              width={2432}
              height={1442}
            />
            <div className="max-w-xl lg:row-start-3 lg:mt-10 lg:max-w-md lg:border-t lg:border-white/20 lg:pt-10">
              <dl className="max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative">
                    <dt className="ml-9 inline-block font-semibold text-white">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-100" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div
            className="pointer-events-none absolute left-12 top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-3xl lg:bottom-[-12rem] lg:top-auto lg:translate-y-0 lg:transform-gpu"
            aria-hidden="true"
          >
            <div
              className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-25"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
