import Header from "./components/Header";
import Main from "./pages/Main";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Schedule from "./pages/Schedule";
import React from "react";
import JohnCraig from "./demos/JohnCraig";
import PowerSolar from "./demos/PowerSolar";

function App() {
  return (
    <BrowserRouter>
      <Header/>
      <Routes>
        <Route exact path='/' element={<Main/>}/>
        <Route exact path='/schedule' element={<Schedule/>}/>
        <Route exact path='/demos/johncraig' element={<JohnCraig/>}/>
        <Route exact path='/demos/solar' element={<PowerSolar/>}/>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
