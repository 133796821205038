import React, {useEffect, useState} from 'react';

function Schedule(props) {
  const [loaded, setLoaded] = useState(false);

  useEffect(()=>{
    if (loaded) return;
    setLoaded(true);
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);
  }, [loaded]);

  return (
    <div className='mt-32 mb-20'>
      <h2 className='text-center text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl mx-12'>Schedule a <span className='text-indigo-600'>No Cost</span> Consultation</h2>
      <p className='text-center text-lg text-gray-900 mt-4 mb-8 mx-12 md:w-1/2 2xl:w-1/3 md:mx-auto'>Our experts would love to help you discover how we can help you boost your business with technology.</p>
      <div className="calendly-inline-widget" data-url="https://calendly.com/astoria-labs/15min?hide_gdpr_banner=1&primary_color=4f46e5" style={{ minWidth: '320px', height: '700px' }}></div>
      <div className='h-16'></div>
      <div className='h-8 bg-indigo-950'></div>
    </div>
  );
}

export default Schedule;
