import React from 'react';
import Hero from "../components/Hero";
import Feature from "../components/Feature";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

function Main() {
  return (
    <>
      <Hero id="hero"/>
      <Feature id="feature"/>
      <Contact id="contact"/>
      <Footer/>
    </>
  );
}

export default Main;
