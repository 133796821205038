import React from 'react';
import brain from "../static/ai-brain-text.svg";

function Header() {
  return (
    <header className="absolute inset-x-0 top-0 z-50 select-none">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 px-8" aria-label="Global">
        <div className="flex flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Astoria Labs</span>
            <img
              className="h-12 w-auto"
              src={brain}
              alt="logo"
            />
          </a>
        </div>
      </nav>
    </header>
  );
}

export default Header;
