import Particles from "react-particles";
import {useCallback, useEffect} from "react";
import { loadSlim } from "tsparticles-slim"

export default function JohnCraig() {

  const particlesInit = useCallback(async engine => {
    await loadSlim(engine);
  }, []);

  const options = {
    "particles": {
      "number": {
        "value": 150,
        "density": {
          "enable": true,
          "value_area": 800
        }
      },
      "color": {
        "value": "#4f46e5"
      },
      "shape": {
        "type": "circle",
        "stroke": {
          "width": 0,
          "color": "#4f46e5"
        },
        "polygon": {
          "nb_sides": 5
        }
      },
      "opacity": {
        "value": 0.2,
        "random": false,
        "anim": {
          "enable": false,
          "speed": 0.3,
          "opacity_min": 0.08,
          "sync": false
        }
      },
      "size": {
        "value": 3,
        "random": true,
        "anim": {
          "enable": false,
          "speed": 12,
          "size_min": 0.1,
          "sync": false
        }
      },
      "line_linked": {
        "enable": true,
        "distance": 150,
        "color": "#4f46e5",
        "opacity": 0.15,
        "width": 1
      },
      "move": {
        "enable": true,
        "speed": 0.6,
        "direction": "none",
        "random": false,
        "straight": false,
        "out_mode": "out",
        "bounce": false,
        "attract": {
          "enable": false,
          "rotateX": 600,
          "rotateY": 1200
        }
      }
    },
    "interactivity": {
      "detect_on": "canvas",
      "events": {
        "onhover": {
          "enable": true,
          "mode": "repulse"
        },
        "onclick": {
          "enable": true,
          "mode": "push"
        },
        "resize": true
      },
      "modes": {
        "grab": {
          "distance": 400,
          "line_linked": {
            "opacity": 1
          }
        },
        "bubble": {
          "distance": 400,
          "size": 40,
          "duration": 2,
          "opacity": 8,
          "speed": 1
        },
        "repulse": {
          "distance": 100,
          "duration": 0.4
        },
        "push": {
          "particles_nb": 1
        },
        "remove": {
          "particles_nb": 2
        }
      }
    },
    "retina_detect": true
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.onload = () => {
      window.voiceflow.chat.load({
        verify: { projectID: '64debc9aaa29af0007d94731' },
        url: 'https://general-runtime.voiceflow.com',
        versionID: 'production'
      });
    };
    script.src = 'https://cdn.voiceflow.com/widget/bundle.mjs';
    script.type = 'text/javascript';
    document.body.appendChild(script);
  }, []);

  return (
    <div className='h-full w-full flex flex-col justify-center align-middle'>
      <Particles
        className='absolute h-full w-full z-10'
        id="tsparticles"
        init={particlesInit}
        options={options}
      />
      <div className='select-none z-20 w-5/6 md:w-3/4 lg:w-1/2 2xl:w-1/3 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center align-middle rounded-2xl bg-indigo-400 bg-opacity-20 p-10 border-2 border-indigo-400'>
        <div className='text-3xl text-center font-bold text-neutral-900'>
          Hey John!
        </div>
        <div className='text-xl mt-8 text-center text-indigo-700'>
          Thanks for checking out this demo we put together for you. Click the button in the bottom right corner to meet Ava!
          <br/>
          <br/>
          This chatbot is also fully customizable, from the way it looks, to its capabilities, and even its tone. Besides saving you hours from repetitive customer support queries, your customers will love having an assistant that can help them in an instant.
        </div>
      </div>
    </div>
  );
}
